const initialState = {
  currentDanceActiveId: null,
  dances: [
    {
      isActive: false,
      id: 1,
      class: "dance01",
      type: "danceInPair",
      name: "salsa LA",
      link: "taniec/salsa-LA",
      image: "instruktorzy/DSC_6856-Edit-Edit.jpg",
      headerImage: "instruktorzy/DSC_6856-Edit-EditA.jpeg",
      video: "https://www.youtube.com/embed/YADn0U3A-Ak",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Styl powstały w Los Angeles głównie za sprawą braci Vazquez, apogeum rozwoju osiągnął w latach 90. XX wieku. Styl L.A. jest tańczony „na 1” (pauza na 4/8, break na 1/5). Jest to styl liniowy (Cross-body), a więc wszystkie figury wykonujemy w jednej linii. Jedyna możliwa zmiana kierunku tańca to dokładnie 180° i następuje poprzez wykonanie podstawowej figury tego stylu: Cross Body Lead. Los Angeles salsa jest stylem bardzo widowiskowym i szybkim; cechuje go duża częstotliwość występowania wielokrotnych obrotów (spinów), elementy typu Butterfly (eksponowanie partnerki) jak i występowanie elementów shines oraz akrobatycznych. Ten styl jest obecnie najpopularniejszym stylem tańczonym w Polsce.`,
      },
    },
    {
      isActive: true,
      id: 2,
      class: "dance02",
      type: ["danceInPair"],
      name: "bachata",
      link: "taniec/bachata",
      image: "instruktorzy/DSC_6709-Edit.jpg",
      headerImage: "instruktorzy/DSC_6709-Edit.jpg",
      video: "https://www.youtube.com/embed/6_4zIY6qCDg",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Bachata to muzyka i taniec z Dominikany, który chyba najczęściej obok merengue towarzyszy salsie na imprezach latynoskich i salsotekach. Podstawy bachaty zna każda osoba, która zetknęła się z salsą i innymi tańcami latynoskimi. Wyróżnia się spośród innych karaibskich rodzajów tańca niezwykłą zmysłowością. Z tego też względu jest to najpopularniejszy taniec wśród zakochanych. Bywają też pary, które przed zatańczeniem bachaty jeszcze nic do siebie nie czują 😉`,
      },
    },
    {
      isActive: true,
      id: 3,
      class: "dance03",
      type: "danceInPair",
      name: "taniec użytkowy",
      link: "taniec/taniec-uzytkowy",
      image: "dances/27.JPG",
      headerImage: "dances/27.JPG",
      video: "https://www.youtube.com/embed/jekwQkpAsfc",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Idealny taniec w parach na tak zwany początek.
            Najprostszy bo ma aż jeden krok jednak wiele możliwości i figur i tego właśnie uczymy się na zajęciach. Krok 2 na 1 świetnie nadaje się do każdego rodzaju muzyki, więc nie ważne co Ci w duszy gra. Ten taniec jest dla Ciebie!`,
      },
    },
    {
      isActive: false,
      id: 4,
      class: "dance04",
      type: "danceInPair",
      name: "zouk",
      link: "taniec/zouk",
      image: "instruktorzy/DSC_6826-Edit.jpg",
      headerImage: "instruktorzy/DSC_6826-Edit.jpg",
      video: "https://www.youtube.com/embed/OTeckUyC28Y",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Zmysłowy… piękny… porywający… ujmujący… trzymający w napięciu… taniec w parach.
            To plątanina taneczna wywodząca się z lambady ale mająca zupełnie inny wyraz artystyczny.
            Nazwa prawdopodobnie stanowi skrót francuskiego słowa „mazurka”, zmodyfikowanego tańca polskiego, zaadaptowanego na Karaibach w XIX, i bardzo popularnego na wyspach na początku XX wieku. Współczesny zouk stanowi mieszaninę calypso i reggae z pewnymi wpływami muzyki amerykańskiej.`,
      },
    },
    {
      isActive: false,
      id: 5,
      class: "dance05",
      type: "danceInPair",
      name: "salsa cubana",
      link: "taniec/salsa-cubana",
      image: "instruktorzy/DSC_6859-Edit-Edit.jpg",
      headerImage: "instruktorzy/DSC_6859-Edit-Edit.jpg",
      video: "https://www.youtube.com/embed/BP5GaWfQsG0",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Dlaczego salsa?
      „Great dancers are not great because of their technique, they are great because of their passion”
      
      Bo nie ogranicza się do jednego stylu, jednej techniki czy jednego rodzaju muzyki. Jest „żywa” - wywodzi się z Kuby lecz pozostaje pod wpływami lokalnych tańców afrykańskich czy portorykańskich dając niemalże nieograniczone możliwości interpretacji. Salsa kubańska to  wolność, swoboda wyrazu i doskonały sposób na to by bawić się tańcem. To przyjemność, bez wymuszonych schematów, bez spisanych reguł technicznych, poradników. Muzyka osadzona jest w clave z prostym charakterystycznym metrum. Jej energetyczny charakter czynią salsę kubańską jednym z prostszych a zarazem najpopularniejszych tańców socialowych na świecie. Niezliczona ilość organizowanych w Polsce i Europie warsztatów, festiwali, kongresów, szkoleń i imprez o charakterze kubańskim a także koncertów najlepszych muzyków i zespołów z Kuby, Peru, Columbii czy Stanów Zjednoczonych, daje nieograniczone możliwości rozwoju i stwarza pole do doskonałej zabawy. Salsa tańczona jest w klubach i na ulicach, w parkach, na estradach. Bez ograniczeń wiekowych, bez podziałów na lepszych i gorszych.
      W salsie liczysz się Ty, Twoja partnerkach i Wasza wspólna pasja. 
      - Sztorcu
      `,
      },
    },
    {
      isActive: true,
      id: 6,
      class: "dance06",
      type: "solo",
      name: "bachata lejdis",
      link: "taniec/bachata-lejdis",
      image: "instruktorzy/DSC_6693-Edit-Edit.jpg",
      headerImage: "instruktorzy/DSC_6693-Edit-Edit.jpg",
      video: "https://www.youtube.com/embed/wPOmfKfUTkU",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Bachata to muzyka i taniec z Dominikany, który chyba najczęściej obok merengue towarzyszy salsie na imprezach latynoskich i salsotekach.
            Podstawy bachaty zna każda osoba, która zetknęła się z salsą i innymi tańcami latynoskimi. Wyróżnia się spośród innych karaibskich rodzajów tańca niezwykłą zmysłowością. 
            Z tego też względu jest to najpopularniejszy taniec wśród zakochanych.
            Bywają też pary, które przed zatańczeniem bachaty jeszcze nic do siebie nie czują 😉`,
      },
    },
    {
      isActive: false,
      id: 7,
      class: "dance07",
      type: "danceFormation",
      name: "dance flow",
      link: "taniec/dance-flow",
      image: "instruktorzy/DSC_6744-Edit.jpg",
      headerImage: "instruktorzy/DSC_6744-Edit.jpg",
      video: "https://www.youtube.com/embed/gnShYclU4pM",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Dance Flow to formacja której główną techniką jest Jazz a zaraz za nim taniec współczesny. Grupa jest wszechstronna i gotowa na więcej 🙂 
      Formacją opiekuje się instruktor mgr choreografii Kamil Mastalerz.
      Jeśli chcesz dołączyć do tej formacji wymagane są podstawy taneczne i ukończone 16 lat ale jeśli masz wątpliwości/ pytania zadzwoń albo napisz:
      tel. 783952086 lub kontakt@danceflow.pl 
      kto wie może czekamy właśnie na Ciebie :)`,
      },
    },
    {
      isActive: true,
      id: 8,
      class: "dance08",
      type: "solo",
      name: "flamenco",
      link: "taniec/flamenco",
      image: "dances/flamenco_new.jpg",
      headerImage: "dances/flamenco_new.jpg",
      video: "https://www.youtube.com/embed/LIG0r0CCBBw",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `„…Głębsze niż wszystkie studnie i morza otaczające świat.
            Gorętsze niż mury arabskich zamków nagrzanych w palącym słońcu Andaluzji. Piękne, zmysłowe, mistyczne i tajemnicze. Przeszywające jak szpada wbita w kark byka. Dżwięki gitary, krzyk muezzina, żyda i cygana rozcinający krajobraz na pół. Rytualny taniec ognia i wody, pełen dumy i dzikości wyrażający szaleństwo i pragnienie. Flamenco…”
            (na podst.F.Garcia Lorki)`,
      },
    },
    {
      isActive: true,
      id: 9,
      class: "dance09",
      type: ["solo"],
      name: "jazz",
      link: "taniec/jazz",
      image: "instruktorzy/DSC_6802-Edit.jpg",
      headerImage: "instruktorzy/DSC_6802-Edit.jpg",
      video: "https://www.youtube.com/embed/tXv16wrZp2o",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Taniec jazzowy jest połączeniem tańca klasycznego, wspłczesnego, a także akrobatyki.
            Technika ta pozwala na dużą swobodę  i ekspresję w tańcu. Jest to jedna z najtrudniejszych technik tanecznych, jednak systematyczne zajęcia pod okiem profesjonalisty przynoszą znaczące efekty.  Istnieje wiele odmian tańca jazzowego takich jak: modern jazz, afro jazz, lyrical jazz, funky jazz i wiele innych.`,
      },
    },
    {
      isActive: true,
      id: 10,
      class: "dance10",
      type: "solo",
      name: "power stretching",
      link: "taniec/power-stretching",
      image: "instruktorzy/DSC_6738.jpg",
      headerImage: "instruktorzy/DSC_6738.jpg",
      video: "https://www.youtube.com/embed/L_fCqg92qks",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `
      Stretching, czyli ćwiczenia rozciągające to rodzaj zajęć polecanych osobom, które godzinami pozostają w tej samej pozycji – na przykład osobom pracującym przy komputerze, ale też polecamy osobom aktywnym, bo stretching powinien być uzupełnieniem każdego treningu.
      Szkoła Tańca Dance Flow pracuje na autorskiej metodzie "Power Stretching" instruktor tańca i fitness Klary Kupińskiej.
      Metoda ta łączy taniec i rozciąganie, usprawniamy cały organizm (pion, stabilizacja, świadomość ruchu) a głównym celem zajęć jest czysty szpagat :)
       
      Ćwiczenia są dostosowane dla każdego i już po pierwszej godzinie zobaczysz efekty! `,
      },
    },
    {
      isActive: true,
      id: 11,
      class: "dance11",
      type: ["solo"],
      name: "sexy dance",
      link: "taniec/sexy-dance",
      image: "dances/Sexy-Dance.jpg",
      headerImage: "dances/Sexy-Dance.jpg",
      video: "https://www.youtube.com/embed/KApCLmLDo58",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Sexy Dance :
      Co to właściwie jest Sexy Dance? W szkole tańca Dance Flow jest to autorski program naszych instruktorek, znajdziesz tam elementy jazzu, latino, hop hopu a nawet tańców estradowych. Na zajęciach poznasz technikę tańca (obroty, izolacje, rytm, itp.) a to wszystko będzie odziane w kocie ruchy... Nauczysz się pięknych i bardzo kobiecych choreografii i kto wie może nawet postanowisz wejść kiedyś na scenę...`,
      },
    },
    {
      isActive: false,
      id: 12,
      class: "dance12",
      type: "danceFormation",
      name: "belly flow",
      link: "taniec/belly-flow",
      image: "instruktorzy/DSC_6780.jpg",
      headerImage: "instruktorzy/DSC_6780.jpg",
      video: "https://www.youtube.com/embed/8YQM_zfYO7Q",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Formacja tańca brzucha prowadzona przez Klarę Kupińską`,
      },
    },
    {
      isActive: true,
      id: 13,
      class: "dance13",
      type: "solo",
      name: "twerk",
      link: "taniec/twerk",
      image: "dances/twerk.jpg",
      headerImage: "dances/twerk.jpg",
      video: "https://www.youtube.com/embed/yUGsqzR3bJ0",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Twerk pochodzi z USA. Rozpowszechniły go dwie panie znane pod nazwą TWERK TEAM, które zaczęły nagrywać filmiki  ze swoim udziałem i udostępniać je na YouTube.
            Styl ten polega na energicznym potrząsaniu bioder w górę, w dół oraz na boki dzięki czemu wprawiają one w ruch pośladki. Pomaga wysmuklic talię, biodra, nogi oraz pupę, wyrzeźbic sylwetkę oraz spalic kalorie. Na zajęciach z twerku panie mogą poczuć się bardzo kobieco i seksownie, a także mogą poprawić swoją samoocenę i pewność siebie. Znanymi artystami reprezentującymi ten styl są: Kstylis, Tyga, Kid Ink oraz Travis Porter.`,
      },
    },
    {
      isActive: true,
      id: 14,
      class: "dance14",
      type: "solo",
      name: "latino solo",
      link: "taniec/latino-solo",
      image: "instruktorzy/DSC_6672.jpg",
      headerImage: "instruktorzy/DSC_6672.jpg",
      video: "https://www.youtube.com/embed/XVv1Zgcd-Vw",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Gorąca mieszanka taneczna i muzyczna. Każde zajęcia to inna choreografia i inna muzyka. Oscylujemy wokół szeroko pojętych tańców latynoamerykańskich takich jak samba, cha cha, czy rumba, poszerzając horyzont o salsę i bachatę, nie zamykając drogi dla odrobiny freestyle-u… Poznajemy własne ciało, uczymy się poruszać, słuchać i interpretować muzykę, przekazywać emocje ruchem ciała. Zajęcia te rozwiną też Twoją koordynację i pamięć ruchową mającą na celu poprawę izolacji poszczególnych części ciała. Świetna zabawa, niezwykle przyjemna forma kształtowania sylwetki i poprawienia sobie humoru 🙂`,
      },
    },
    {
      isActive: true,
      id: 15,
      class: "dance15",
      type: ["solo"],
      name: "hip-hop",
      link: "taniec/hip-hop",
      image: "dances/hip-hop.jpg",
      headerImage: "dances/hip-hop.jpg",
      video: "https://www.youtube.com/embed/kS_DvUlrukk",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Hip-hop to jeden z najpopularniejszych nowoczesnych stylów tanecznych. Można go zobaczyć w znanych filmach i teledyskach, a jego historia nieodzownie łączy się z muzyką o tej samej nazwie. Hip-hop zaliczany jest do Street Dance, czyli tańców ulicznych, i powstał na ulicach Nowego Jorku. Dzieli się na różne odmiany, np. oldschool, new style, new age, freestyle, co czyni go wyjątkowo różnorodnym, a każdy miłośnik tańca znajdzie w nim coś dla siebie. `,
      },
    },
    //comented due to coming back in 2 months
    {
      isActive: true,
      id: 16,
      class: "dance16",
      type: ["solo"],
      name: "taniec współczesny",
      link: "taniec/taniec-wspolczesny",
      image: "instruktorzy/DSC_6792-Edit.jpg",
      headerImage: "instruktorzy/DSC_6792-Edit.jpg",
      video: "https://www.youtube.com/embed/PAE5_e9JGTM",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Taniec współczesny - 
      WSPÓŁCZESNY / CONTEMPORARY
      Taniec współczesny w głównej mierze oparty jest na idei baletu, ale czerpie z niego tylko to, co najlepsze omijając sztywne zasady i reguły, stawiając na pokazanie emocji tancerza , który tańcząc układ, stara się opowiedzieć odbiorcy pewną historię. 
      Taniec współczesny ma bardzo luźne granice i standardy, nie hamuje inwencji tancerza i pozwala mu na dowolność w kreowaniu ruchu`,
      },
    },
    {
      isActive: false,
      id: 17,
      class: "dance17",
      type: "solo",
      name: "broadway jazz",
      link: "taniec/broadway-jazz",
      image: "dances/boradway-jazz.jpg",
      headerImage: "dances/boradway-jazz.jpg",
      video: "https://www.youtube.com/embed/wBLn-NzbUak",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `
      Broadway Jazz to zajęcia skierowane do osób, które chciałyby zatańczyć jak główni bohaterowie znanych nowojorskich musicali. Taniec ten korzysta z wielu technik tanecznych i jest nieodłączny z muzyką. To elegancja połączona z energią, precyzja i subtelność, szyk i aktorstwo sceniczne. Na naszych zajęciach będziemy sięgać do znanej i lubianej muzycznej klasyki i przede wszystkim dobrze się bawić.`,
      },
    },
    {
      isActive: true,
      id: 18,
      class: "dance18",
      type: ["solo"],
      name: "body ballet",
      link: "taniec/body-ballet",
      image: "instruktorzy/DSC_6813-Edit.jpg",
      headerImage: "instruktorzy/DSC_6813-Edit.jpg",
      video: "https://www.youtube.com/embed/VzM9Mt_cpq0",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Zajęcia Body Ballet dedykowane są dla każdego, niezależnie od wieku oraz stopnia przygotowania.

            Zajęcia prowadzone są według metody autorskiej naszej instruktorki. Ich program zawiera gimnastykę baletową na podłodze w pozycji siedzącej, stretching – rozciąganie – rozwijające plastyczność i elastyczność ciała, podstawowe elementy tradycyjnego baletowego „drążka” w uproszczonym sposobie, oraz podstawowe taneczne etiudy, małe choreografie a może nawet wielkie spektakle taneczne.
            
            Jeśli wasze marzenia z lat dziecięcych o tańcu nie ziściły się – zachęcamy spróbować, ponieważ w każdym wieku jest to możliwe. Body Ballet jest techniką nie tylko dla ludzi młodych, jest to nauka do opanowania przez każdego z nas, kto chce osiągnąć grację ruchu bez niepotrzebnych obciążeń dla ciała, wspaniałą postawę, wzmocnienie mięśni, oraz kto chce pozbyć się zbędnych kilogramów i centymetrów w pasie. Dzięki Body Ballet, pojawia się również inny stosunek do własnego ciała i wzrasta wiara w siebie. Zajęcia prowadzone są tak akby każdy czuł się dobrze i czuł że się uczy i rozwija.`,
      },
    },
    {
      isActive: true,
      id: 19,
      class: "dance19",
      type: ["solo"],
      name: "high heels",
      link: "taniec/high-heels",
      image: "instruktorzy/DSC_6755-Edit.jpg",
      headerImage: "instruktorzy/DSC_6755-Edit.jpg",
      video: "https://www.youtube.com/embed/VmVLGLg-QBk",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `High Heels to zajęcia taneczne na wysokich obcasach. Nie ważne czy tańczyłaś wcześniej czy też nie te zajęcia są dla kobiet które kochają szpilki!
      Poznasz tu różne techniki tańca np. vouging, dancehall czy sexy dance. Zapraszamy!`,
      },
    },
    {
      isActive: true,
      id: 20,
      class: "dance20",
      type: "kids",
      name: "balet",
      link: "taniec/balet",
      image: "dances/kids2.jpg",
      headerImage: "dances/kids2.jpg",
      video: "",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: " ",
        end: `Na tych zajęciach dzieci poznają podstawy baletu, ale też nauczą się jak ustawić prostą sylwetkę, wykonać poprawny obrót i poznają dyscyplinę.
            Pojawią się też elementy innych tańców wplatane za pomocą animacji tanecznych do różnego rodzaju muzyki. Nasze zajęcia dla dzieci są wyjątkowe i bardzo szybko pojawią się pierwsze efekty.
konieczne są zapisy wcześniej - liczba miejsc ograniczona
masz pytania :) dzwoń tel.783952086 lub pisz kontakt@danceflow.pl`,
      },
    },
//     {
//       isActive: false,
//       id: 21,
//       class: "dance21",
//       type: "danceFormation",
//       name: "dance flow-junior",
//       link: "taniec/dance-flow-junior",
//       image: "dances/formacja-jazzowa-dzieci.jpg",
//       headerImage: "dances/formacja-jazzowa-dzieci.jpg",
//       video: "",
//       poster: "/assets/poster.png",
//       content: {
//         middle: `Zbieramy zapisy - start zajęć styczeń 2021 rok!`,
//         acapits: " ",
//         end: `
//         Dance flow junior - formacja Jazzowa 10-15 lat
//             Program grupy formacyjnej będzie obejmował technikę tańca jazzowego – ćwiczenia budujące ciało i formę tancerza, praca z muzyką, oddechem oraz emocjami wyrażanymi w tańcu, a ponadto rozwój kreatywności tancerza poprzez budowanie choreografii i ćwiczenia improwizacyjne. Podczas zajęć technika jazzowa nie będzie jedyną formą pracy. Obecne będą elementy techniki tańca klasycznego oraz skupienie nad elastycznością i plastycznością ciała uczestnika – ćwiczenia rozciągające, a także ćwiczenia siłowe i wzmacniające mięśnie. Zajęcia przeznaczone dla osób chcących rozwijać swoje dotychczasowe umiejętności, nabyć nową wiedzę praktyczną i teoretyczną z zakresu wymienionych wyżej technik tanecznych oraz zarówno dla osób, które chcą zacząć swoją przygodę z tańcem, będąc zmotywowane do intensywnej pracy. Celem jest stworzenie stałej grupy na wysokim poziomie, biorącej udział w turniejach i pokazach tanecznych, która będzie godnie reprezentować Wrocławskią Szkołę Tańca Dance Flow. Dodatkowo dzieci nauczą się pracy w grupie i będą spędzać czas w miłej atmosferze o którą dbamy 🙂
//             Niech narodzi się wielka pasja a taniec niech będzie przyjemną formą spędzania wolnego czasu 🙂
            
//             Formacja Jazzowa Dance Flow - Junior powstała we wrzesniu 2018 roku.
// Pierwszy występ publiczny, pierwszy udział w turnieju i pierwszy sukces maj 2019 - 3 miejsce w Ogólnopolskim Turnieju Tańca Nowoczesnego.
// masz pytanie :) dzwoń tel.783952086 lub pisz kontakt@danceflow.pl
//             `,
//       },
//     },
    {
      isActive: false,
      id: 22,
      class: "dance22",
      type: "danceFormation",
      name: "flow girls",
      link: "taniec/flow-girls",
      image: "girls/DSC_6902-Edit-Edit.jpg",
      headerImage: "girls/DSC_6902-Edit-Edit.jpg",
      video: "",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Flow Girls – profesjonalna formacja taneczna której głównym opiekunem jest instruktor i choreograf Klara Kupińska
            Dziewczyny tańczą wszystko co kobiece… od salsy przez sexy dance i jazz po high heels a nawet twerk.
            Ich największą pasją jest taniec a warunkiem uczestnictwa w tych zajęciach jest ciężka praca i zgoda instruktora.
            Jeśli chcesz żeby Flow Girls wystąpiły na Twojej imprezie napisz do nas 🙂
            Mamy gotowy program lub przygotujemy program taneczny specjalnie dla Ciebie.
            Kontakt:
            Klara Kupińska
            Telefon: 783952086
            Email: kontakt@danceflow.pl`,
      },
    },
    {
      isActive: false,
      id: 23,
      class: "dance23",
      type: "saturday",
      name: "jazz",
      link: "taniec/jazz",
      image: "dances/jazz.jpeg",
      headerImage: "dances/jazz.jpeg",
      video: "https://www.youtube.com/embed/tXv16wrZp2o",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Taniec jazzowy jest połączeniem tańca klasycznego, współczesnego, a także akrobatyki.
            Technika ta pozwala na dużą swobodę  i ekspresję w tańcu. Jest to jedna z najtrudniejszych technik tanecznych, jednak systematyczne zajęcia pod okiem profesjonalisty przynoszą znaczące efekty.  Istnieje wiele odmian tańca jazzowego takich jak: modern jazz, afro jazz, lyrical jazz, funky jazz i wiele innych.`,
      },
    },
    {
      isActive: false,
      id: 24,
      class: "dance24",
      type: "saturday",
      name: "body-ballet",
      link: "taniec/body-ballet",
      image: "instruktorzy/DSC_6813-Edit.jpg",
      headerImage: "instruktorzy/DSC_6813-Edit.jpg",
      video: "",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Zajęcia Body Ballet dedykowane są dla każdego, niezależnie od wieku oraz stopnia przygotowania.

            Zajęcia prowadzone są według metody autorskiej naszej instruktorki. Ich program zawiera gimnastykę baletową na podłodze w pozycji siedzącej, stretching – rozciąganie – rozwijające plastyczność i elastyczność ciała, podstawowe elementy tradycyjnego baletowego „drążka” w uproszczonym sposobie, oraz podstawowe taneczne etiudy, małe choreografie a może nawet wielkie spektakle taneczne.
            
            Jeśli wasze marzenia z lat dziecięcych o tańcu nie ziściły się – zachęcamy spróbować, ponieważ w każdym wieku jest to możliwe. Body Ballet jest techniką nie tylko dla ludzi młodych, jest to nauka do opanowania przez każdego z nas, kto chce osiągnąć grację ruchu bez niepotrzebnych obciążeń dla ciała, wspaniałą postawę, wzmocnienie mięśni, oraz kto chce pozbyć się zbędnych kilogramów i centymetrów w pasie. Dzięki Body Ballet, pojawia się również inny stosunek do własnego ciała i wzrasta wiara w siebie. Zajęcia prowadzone są tak akby każdy czuł się dobrze i czuł że się uczy i rozwija.`,
      },
    },
    {
      isActive: true,
      id: 25,
      class: "dance25",
      type: "solo",
      name: "fit dance",
      link: "taniec/fit-dance",
      image: "dances/df132.jpg",
      headerImage: "dances/df132.jpg",
      video: "https://www.youtube.com/",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Autorski program zajęć naszej instruktorki tańca i instruktorki Fitness Klary Kupińskiej. Na zajęciach będzie część poświęcona na dobre rozgrzaniem i spalanie + ćwiczenia na pośladki i brzuch.
        Zajęcia dla każdego początkującego i trenującego. Dla każdego kto chce zadbać o zdrowie i kondycję a przy okazji zgubić kilka zbędnych centymetrów 🙂`,
      },
    },
    {
      isActive: false,
      id: 26,
      class: "dance26",
      type: "morning",
      name: "power stretching",
      link: "taniec/power-stretching",
      image: "instruktorzy/DSC_6738.jpg",
      headerImage: "instruktorzy/DSC_6738.jpg",
      video: "https://www.youtube.com/embed/L_fCqg92qks",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Stretching, czyli ćwiczenia rozciągające
            to rodzaj zajęć polecanych osobom, które godzinami pozostają w tej samej pozycji – na przykład osobom pracującym przy komputerze, ale też polecamy osobom aktywnym ,bo stretching powinien być uzupełnieniem każdego treningu. Ćwiczenia są dostosowane dla każdego i już po pierwszej godzinie zobaczysz efekty!`,
      },
    },
    {
      isActive: false,
      id: 27,
      class: "dance27",
      type: "morning",
      name: "latino solo",
      link: "taniec/latino-solo",
      image: "instruktorzy/DSC_6672.jpg",
      headerImage: "instruktorzy/DSC_6672.jpg",
      video: "https://www.youtube.com/embed/XVv1Zgcd-Vw",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Gorąca mieszanka taneczna i muzyczna. Każde zajęcia to inna choreografia i inna muzyka. Oscylujemy wokół szeroko pojętych tańców latynoamerykańskich takich jak samba, cha cha, czy rumba, poszerzając horyzont o salsę i bachatę, nie zamykając drogi dla odrobiny freestyle-u…poznajemy własne ciało, uczymy się poruszać, słuchać i interpretować muzykę, przekazywać emocje ruchem ciała. Zajęcia te rozwiną też Twoją koordynację i pamięć ruchową mającą na celu poprawę izolacji poszczególnych części ciała. Świetna zabawa, niezwykle przyjemna forma kształtowania sylwetki i poprawienia sobie humoru `,
      },
    },
    {
      isActive: false,
      id: 28,
      class: "dance28",
      type: "danceInPair",
      name: "tańce swingowe",
      link: "taniec/tance-swingowe",
      image: "dances/tance-swingowe.jpg",
      headerImage: "dances/tance-swingowe.jpg",
      video: "https://www.youtube.com/embed/AehnnJzWO80",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `SWING – dynamiczny taniec towarzyski, wykonywany do rytmów jazzu i rockabilly.
            Najbardziej popularny w latach 20 XX wieku, dziś jest bardzo niszowy. Charakteryzuje się szybkim tempem i figurami półakrobatycznymi. Przez lata wyodrębniły się różne rodzaje tańca swingowego, m.in. boogie woogie, lindy hop, charleston, jive, shag. Często uważany za podobny do rock'n'roll'a ze względu na podobny klimat muzyczny i podobne charakteryzacje tancerzy.
            Aktualnie może być wykonywany również solo i czasem jest modyfikowany na potrzeby tańca do muzyki elektronicznej, stąd nazwa młodszego gatunku – electroswing.`,
      },
    },
    {
      isActive: true,
      id: 29,
      class: "dance29",
      type: ["danceInPair", "kids"],
      name: "taniec towarzyski",
      link: "taniec/taniec-towarzyski",
      image: "dances/25.JPG",
      headerImage: "dances/25.JPG",
      video: "https://www.youtube.com/embed/ddX1zEtnRaA",
      poster: "/assets/poster.png",
      content: {
        end: `Taniec towarzyski dla dzieci to możliwość poznania klasycznych kroków walca angielskiego czy foxtrota oraz latynoamerykańskich - rumby, samby, cha cha cha. To styl dla dzieci, które marzą o występach na dużej scenie podczas turniejów tanecznych.
Nasza instruktorka posiada wieloletnie doświadczenie w pracy z dziećmi jak i doświadczenie turniejowe.`,
        acapits: "...",
        middle: `Taniec Towarzyski (z łac.ballare, tłum. do tańca) – forma rozrywki wywodząca się z tańców salonowych i zabaw ludowych.
            Na tych zajęciach poznasz podstawy 10 tańców:
            Tańce standardowe (ST) (kolejność jak na turniejach)
            – walc angielski
            – tango (dawniej taniec latynoamerykański)
            – walc wiedeński
            – foxtrot
            – quickstep
            Tańce latynoamerykańskie (LA) (kolejność jak na turniejach)
            – samba
            – cha-cha
            – rumba
            – pasodoble (dawniej taniec standardowy)
            – jive
            Po za krokami figurami i nowymi rytmami Panowie nauczą się prowadzenia partnerki, a Panie słuchania partnera (oczywiście w tańcu 🙂).`,
      },
    },
    {
      isActive: false,
      id: 30,
      class: "dance30",
      type: "",
      name: "flamenco",
      link: "taniec/flamenco",
      image: "dances/Flamenco.jpg",
      headerImage: "dances/Flamenco.jpg",
      video: "https://www.youtube.com/embed/LIG0r0CCBBw",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `„…Głębsze niż wszystkie studnie i morza otaczające świat.
            Gorętsze niż mury arabskich zamków nagrzanych w palącym słońcu Andaluzji. Piękne, zmysłowe, mistyczne i tajemnicze. Przeszywające jak szpada wbita w kark byka. Dżwięki gitary, krzyk muezzina, żyda i cygana rozcinający krajobraz na pół. Rytualny taniec ognia i wody, pełen dumy i dzikości wyrażający szaleństwo i pragnienie. Flamenco…”
            (na podst.F.Garcia Lorki)`,
      },
    },
    {
      isActive: false,
      id: 31,
      class: "dance31",
      type: "saturday",
      name: "taniec towarzyski",
      link: "taniec/taniec-towarzyski",
      image: "instruktorzy/DSC_6857-Edit-Edit.jpg",
      headerImage: "instruktorzy/DSC_6857-Edit-Edit.jpg",
      video: "https://www.youtube.com/embed/ddX1zEtnRaA",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Taniec Towarzyski (z łac.ballare, tłum. do tańca) – forma rozrywki wywodząca się z tańców salonowych i zabaw ludowych.
            Na tych zajęciach poznasz podstawy 10 tańców:
            Tańce standardowe (ST) (kolejność jak na turniejach)
            – walc angielski
            – tango (dawniej taniec latynoamerykański)
            – walc wiedeński
            – foxtrot
            – quickstep
            Tańce latynoamerykańskie (LA) (kolejność jak na turniejach)
            – samba
            – cha-cha
            – rumba
            – pasodoble (dawniej taniec standardowy)
            – jive
            Po za krokami figurami i nowymi rytmami Panowie nauczą się prowadzenia partnerki, a Panie słuchania partnera (oczywiście w tańcu 🙂).`,
      },
    },
    {
      isActive: true,
      id: 32,
      class: "dance32",
      type: "solo",
      name: "reggaeton",
      link: "taniec/reggaeton",
      image: "instructors/julia-reggaeton.jpg",
      headerImage: "instructors/julia-reggaeton.jpg",
      video: "https://www.youtube.com/embed/uwcZdvY0YUI",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Reggaeton - to streetowy styl taneczny, który wyróżnia się gorącym, wyzwolonym i pewnym siebie klimatem.

        Reggaeton wywodzi się z Puerto Rico. Podstawowym elementem tego tańca są silne i krótkie ruchy klatki piersiowej oraz bioder, jak również liczne izolacje i szejki (shake'i). `,
      },
    },
    {
      isActive: false,
      id: 33,
      class: "dance33",
      type: "kids",
      name: "taniec towarzyski",
      link: "taniec/taniec-towarzyski",
      image: "dances/kidsdanceinpair.jpg",
      headerImage: "dances/kidsdanceinpair.jpg",
      video: "",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Taniec Towarzyski
      to 5 tańców latynoamerykańskich - cha cha, rumba, samba, jive, paso-doble
      i 5 tańców standardowych - walc angielski, walc wiedeński, tango, quick step, foxtrot 
       
      Dzieci uczą się kroków, figur, poprawnej postawy, rytmu i muzyki. Uczą się solo i tańczą w parach.
      Te zajęcia to też kultura zachowania i współpracy z partnerem/partnerką - wzajemnego szacunku.
      Taniec Towarzyski to przyjemność i sport - szkoła tańca Dance Flow chętnie uczestniczy w turniejach tanecznych jeśli rodzice i dzieci wyrażą taką chęć.
       
      Zapraszamy na nasze zajęcia w Dance Flow dzieci czują się jak w domu :)`,
      },
    },
    {
      isActive: false,
      id: 34,
      class: "dance34",
      type: "kids",
      name: "flamenco kids",
      link: "taniec/flamenco-kids",
      image: "dances/flamencokids.jpg",
      headerImage: "dances/flamencokids.jpg",
      video: "",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Flamenco
      Piękny hiszpański taniec, ale nie tylko taniec bo flamenco to też muzyka, rytm, kultura, zabawa i dyscyplina.
      Taniec ten rozwija wspaniale poczucie rytmu, koordynację ruchową, grację ruchu, muzykalność, wpływa na koncentrację. Zajęcia flamenco to również jednoczesne poznawanie kultury hiszpańskiej, języka czy zwyczajów. Zajęcia prowadzi właścicielka szkoły tańca Klara Kupińska - flamenco jest w jej życiu od dzieciństwa i z wielką radością przekazuje tą pasję i miłość dalej. `,
      },
    },
    {
      isActive: true,
      id: 35,
      class: "dance35",
      type: "kids",
      name: "mixdance",
      link: "taniec/mixdance",
      image: "dances/mixdance.jpg",
      headerImage: "dances/mixdance.jpg",
      video: "https://www.youtube.com/",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: " ",
        end: ` Mixdance – to połączenie różnych styli tanecznych; od tańca klasycznego po taniec disco.
      Zajęcia w wyjątkowy sposób kształtują muzykalność, a także rozwijają świadomość ciała i
      koordynację ruchową.
      Dzięki temu, ze dzieci mają do czynienia z różnymi technikami – mają okazję odkryć to, co
      je najbardziej interesuje, a następnie rozwijać się właśnie w tym wybranym stylu.`,
      },
    },
    {
      isActive: false,
      id: 36,
      class: "dance36",
      type: "kids",
      name: "videoclip dance",
      link: "taniec/videoclip-dance",
      image: "dances/videoclipdance.jpg",
      headerImage: "dances/videoclipdance.jpg",
      video: "https://www.youtube.com/",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `MVideo clip dance – forma tańca teledyskowego, który cechuje dynamika i różnorodność. Jest
      to kombinacja różnych styli tanecznych, m.in. hip hop, jazz i showdance. Urozmaiceniem
      tych zajęć są elementy teatralne. Na tych zajęciach dzieci poczują się jak gwiazdy teledysku.`,
      },
    },
    {
      isActive: false,
      id: 37,
      class: "dance37",
      type: ["solo"],
      name: "akrobatyka",
      link: "taniec/akrobatyka",
      image: "dances/akrobatyka.jpg",
      headerImage: "dances/akrobatyka.jpg",
      video: "https://www.youtube.com/",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Zajęcia akrobatyka
      Zajęcia z akrobatyki tanecznej niosą ze sobą wiele dobrego- wzmacniają mięśnie, wspomagają ich rozciągnięcie, pozwalają przezwyciężyć strach, a także dają dużą satysfakcję, kiedy okazuje się, że nawet najtrudniejsze akrobacje z czasem stają się dziecinnie proste. Na tych zajęciach kursanci poznają akrobatykę od podstaw, przydatnych i często wykorzystywanych w technikach takich jak jazz czy taniec współczesny.`,
      },
    },
    {
      isActive: false,
      id: 38,
      class: "dance38",
      type: "solo",
      name: "fit latino",
      link: "taniec/fit-latino",
      image: "dances/FitLatino.jpg",
      headerImage: "dances/FitLatino.jpg",
      video: "https://www.youtube.com/",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Fit Latino to taneczny trening - połączenie tańca i fitness. Zajęcia idealne dla osób które chcą zgubić zbędne kilogramy, wyrzeźbić sylwetkę a przy okazji kochają latynoskie klimaty i taniec! Na tych zajęciach zmęczysz się w przyjemny taneczny sposób :)
      Autorski program szkoły tańca Dance Flow - zapraszamy!`,
      },
    },
    {
      isActive: false,
      id: 39,
      class: "dance39",
      type: "danceInPair",
      name: "tango argentyńskie",
      link: "taniec/tango-argentynskie",
      image: "dances/tango_arg.jpg",
      headerImage: "dances/tango_arg.jpg",
      video: "https://www.youtube.com/embed/yB-mMrWYado",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Tango argentyńskie
        Jest to taniec pochodzący z Argentyny Buenos Aires i Urugwaju, obecnie spopularyzowany na całym świecie. 
        Tango argentyńskie, zarówno muzyka jak i taniec różni się od tanga znanego z tańców towarzyskich (ballroom). 
        Charakteryzuje się bliskim objęciem par i improwizacją, jest nieodłączny od kultury, z której się wywodzi. 
        Tango wpisane jest na listę niematerialnego dziedzictwa UNESCO. `,
      },
    },
    {
      isActive: false,
      id: 40,
      class: "dance40",
      type: "solo",
      name: "technika tańca-jazzowego",
      link: "taniec/technika-tanca-jazzowego",
      image: "instruktorzy/DSC_6748-Edit.jpg",
      headerImage: "instruktorzy/DSC_6748-Edit.jpg",
      video: "https://www.youtube.com/",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Technika Tańca Jazzowego - Zajęcia skierowane do osób, które miały już styczność z tańcem jazzowym. Ćwiczenia oparte o technikę Marthy Graham, Lestera Hortona oraz Jose LLimona wraz z eementami tańca klasycznego.
         Leckcje skladają się z kombinacji tanecznych mających na celu rozwój motoryczny u tancerza oraz odpowiednie balansowanie energią.`,
      },
    },
    {
      isActive: true,
      id: 41,
      class: "dance41",
      type: "danceInPair",
      name: "pierwszy taniec",
      link: "taniec/pierwszy-taniec",
      image: "dances/danceInPairs.jpg",
      headerImage: "dances/danceInPairs.jpg",
      video: "https://www.youtube.com/embed/FwZhxcImb3A",
      poster: "/assets/poster.png",
      content: {
        middle: `Kurs Tańca Pierwszy Taniec to zajęcia grupowe dla wszystkich par narzeczonych które planują ślub oraz wesele.
          Jak wiadomo żadne wesele nie może odbyć się bez pary młodej : i
           bez  pięknego i wyjątkowego Pierwszego Tańca w ich wykonaniu i 
           tutaj na ratunek instruktor tańca i właścicielka szkoły tańca
            Dance Flow Klara Kupińska pomoże każdemu! Klara od 2008 roku
             pracuje z młodymi parami ucząc nie tylko kroków,  figur ale
              przed wszystkim prowadzenia partnerów i słuchania partnerki - z
               tego wszystkiego tworzy piękne i wymarzone choreografie do których będziecie wracać we wspomnieniach latami a wasi weselni 
               goście będą opowiadać o tym tańcu przy wszystkich możliwych okazjach rodzinnych!
           
          `,
        acapits:
          " Program zajęć: taniec użytkowy/discofox, walc angielski, walc wiedeński, akrobacje i dodatki (wykonalne dla każdej pary - efektowne dla wszystkich gości)",
        end: ` kurs tańca od chwili startu trwa 3 miesiące. Zapisy oczywiście Parami :)`,
      },
    },
    {
      isActive: false,
      id: 42,
      class: "dance42",
      type: "solo",
      name: "salsa solo",
      link: "taniec/salsa-solo",
      image: "instructors/DSC02378.png",
      headerImage: "instructors/DSC02378.png",
      video: "https://www.youtube.com/embed/KogVupCLXWU",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Salsa solo to zajęcia taneczne na których poznasz podstawy Salsy Kubańskiej i szeroko pojętych tańców Afrokubańskich takich jak Timba, Reggaeton, Afro oraz Rumba Kubańska.
        Dodatkowo na tych zajęciach ukształtujesz swoją muzykalność i rozwiniesz rozumienie rytmu.
        Jeśli kochasz latynoską muzykę i zawsze chciałaś/chciałeś nauczyć się salsy, poczuć wolność i zrozumieć, że taniec to zabawa... te zajęcia są właśnie dla Ciebie!!!`,
      },
    },
    {
      isActive: false,
      id: 43,
      class: "dance43",
      type: "danceInPair",
      name: "salsa NY",
      link: "taniec/salsa-NY",
      image: "instructors/Rami&Kamila.jpg",
      headerImage: "instructors/Rami&Kamila.jpg",
      video: "https://www.youtube.com/",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Salsa New York Style on 2 to styl cross-body, czasem nazywany mambo on 2 lub salsa on 2. Stworzony w latach 70. przez Eddiego Torresa (urodzonego w Puerto Rico) przez połączenie oryginalnego mambo z latin hustle i innymi stylami tańca takimi jak pachanga i boogaloo. Od tego czasu rozwinął się pod wpływem między innymi tańca jazzowego, tańca współczesnego, tańców afrokubańskich. W latach 60. XX wieku w dzielnicach kulturalnej "mieszanki" Nowego Jorku, najróżniejsze style taneczne zmieszały się ze sobą i tak powstała salsa. 
        Jej składnikami były: kubański son, rumba i cha-cha, bomba i plena z Puerto Rico i przede wszystkim mambo. Tak powstała szybka mieszanka "Salsa New York Style": szybkie kombinacje kroków i efektowne obroty (wywodzące się z północnoamerykańskich tańców hustle, swing), zmiany między tańcem w parach a elementami solowymi (wpływy portorykańskie). Ruchy są łagodne, eleganckie. Kobiety cechuje w tym stylu zmysłowość, natomiast u mężczyzn styl wyróżnia się elegancją i męskością. `,
      },
    },
    {
      isActive: true,
      id: 44,
      class: "dance44",
      type: ["kids", "solo"],
      name: "street dance",
      link: "taniec/street-dance",
      image: "dances/neder_street.jpg",
      headerImage: "dances/neder_street.jpg",
      video: "https://www.youtube.com/",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Zajęcia street dance to nie tylko nauka tańca, ale także 100% tanecznego luzu i doskonałej zabawy, dużo ruchu, werwy i dobrze sporzytkowanej energii. Głównym celem zajęć jest zapoznanie dzieci z podstawowymi krokami w stylu street dance i nauka krótkich układów tanecznych.
Nasz instruktor posiada wieloletnie doświadczenie w pracy z dziećmi i młodzieżą po za nauką tańca dba zarówno o dyscyplinę jak i zabawę. `,
      },
    },
    {
      isActive: false,
      id: 45,
      class: "dance45",
      type: "solo",
      name: "reggaeton",
      link: "taniec/reggaeton",
      image: "dances/reggaeton.jpg",
      headerImage: "dances/reggeaton.jpg",
      video: "https://www.youtube.com/embed/lBVA3vNrvRw",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Latynoski taniec uliczny tańczony do muzyki o tej samej nazwie. Powstał w Puerto Rico w latch 90 XX wieku. Inspirację czerpie głównie z hip-hop i ragga. Zajęcia zawierają szeroko rozumiany bodymovement - praca klatki i bioder , wayvy i shaky, seksowne i kobiece ruchy. Pracę nad rytmicznością i koordynacją. A to wszystko do energetycznej latynoskiej muzyki, takich hitów jak Gasolina (Daddy Yankee) czy Despacito (Luis Fonsi).`,
      },
    },
    {
      isActive: false,
      id: 46,
      class: "dance46",
      type: "danceFormation",
      name: "best flow",
      link: "taniec/best-flow",
      image: "formacja/best-flow.jpg",
      headerImage: "formacja/best-flow.jpg",
      video: "https://www.youtube.com/",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Formacja Best Flow to okazja do szukania nowych ruchów i rozwiązań, ale też ciągłej pracy nad techniką. Będziemy wykorzystywać wyobraźnię, aby tworzyć nowe choreografie i móc maksymalizować ilość emocji, która towarzyszy nam w trakcie tańca. Jeśli uważasz, że ciężka praca i determinacja to twoje mocne strony to formacja z tańca współczesnego będzie dla Ciebie!`,
      },
    },
    {
      isActive: true,
      id: 47,
      class: "dance47",
      type: "danceFormation",
      name: "flow heels",
      link: "taniec/flow-heels",
      image: "dances/flowheels.jpg",
      headerImage: "dances/flowheels.jpg",
      video: "https://www.youtube.com/",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Flow Heels to formacja, której podstawą jest taniec na wysokich obcasach – High Heels. Łączy on elementy dancehallu, jazzu, sexy dance, frame up oraz vougingu. Zajęcia formacji to szansa na doskonalenie umiejętności tanecznych oraz rozwijanie świadomości własnego ciała i seksualności. Grupa będzie pracować nad techniką, choreografią, improwizacją oraz rozciąganiem i wzmacnianiem mięśni. Zajęcia formacji prowadzi Masha Sheveleva – tancerka i instruktorka z wieloletnim doświadczeniem. Jeśli czujesz się pewnie w butach na wysokich obcasach – dołącz do Flow Heels!`,
      },
    },
    {
      isActive: true,
      id: 48,
      class: "dance48",
      type: "solo",
      name: "jazz funk",
      link: "taniec/jazz-funk",
      image: "instructors/masha.jpg",
      headerImage: "instructors/masha.jpg",
      video: "https://www.youtube.com/",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `JAZZ FUNK łączy w sobie wiele elementów różnych styli tanecznych. Jest to gatunek umożliwiający opowiadanie historii ruchem. Styl bardzo barwny emocjonalnie i energetycznie. Choreografie mogą być zarówno liryczne jak i czysto rytmiczne- to samo się tyczy klimatu muzycznego`,
      },
    },
    {
      isActive: false,
      id: 49,
      class: "dance49",
      type: "solo",
      name: "vogue",
      link: "taniec/vogue",
      image: "dances/vogue.JPG",
      headerImage: "dances/vogue.JPG",
      video: "https://www.youtube.com/",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Vogue - Został stworzony przez społeczeństwo queerowe w Stanach Zjednoczonych jeszcze w latach 70-tych.

        Jest to bardzo różnorodny styl tańca, czerpiący swoje elementy ze świata wybiegów mody, gimnastyki, baletu a nawet sztuk walki. W początkowym etapie zajęć zapoznamy się z podstawami Vogue Fem – najbardziej współczesnym rodzajem tego tańca. Vogue jest najczęściej tańcem improwizowanym i na zawodach, które nazywają się 'balami', uczestnicy walczą między sobą na stylizowanym wybiegu do dowolnej Voguowej muzyki. Na zajęciach sporo czasu poświęcimy nie tylko technice i układom, ale także nad freestylem. 
        
        Oprócz tego przejdziemy także przez podstawy New Way – rodzaju Vogue'a, który tańczy się do muzyki House. 
        
        Vogue – to nie tylko styl tańca, ale przede wszystkim kultura, która jest inkluzywna i wszechstronna, gdzie najbardziej ceni się i podkreśla 'attitude' oraz pewność siebie.`,
      },
    },
    {
      isActive: false,
      id: 21,
      class: "dance48",
      type: "danceInPair",
      name: "kizomba",
      link: "taniec/kizomba",
      image: "dances/kizomba_jk.png",
      headerImage: "dances/kizomba_jk.png",
      video: "https://www.youtube.com/",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Kizomba, znaczy impreza. Taniec ten wyewoluował w krajach Arfykańskich, z tańców plemiennych, zaadaptowanych do tańca w parach, na modłę Europejską. Kizomba to taniec głównie chodzony, do muzyki w średnim tempie. Jest to również najbardziej kontaktowy taniec, tańczony popularnie na całym świecie, gdzie partnerzy stykają się klatką i brzuchem, a w trakcie ruchu kontakt występuje też na fragmentach nóg. Obecnie w każdym większym mieście na świecie odbywają się imprezy kizombowe, które sprzyjają budowaniu znajomości i przyjaźni ponad granicami.`,
      },
    },
    {
      isActive: true,
      id: 50,
      class: "dance50",
      type: "danceFormation",
      name: "flow show",
      link: "taniec/flow-show",
      image: "instruktorzy/danceshow.jpg",
      headerImage: "instruktorzy/danceshow.jpg",
      video: "https://www.youtube.com/",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Flow Show będzie formacją oparta na tańcach latynoamerykańskich z elementami show. Energia latino, pasja, radość i kobieca siła w najlepszej odsłonie. Będziemy skupiać się na choreografii, technice tańca, wyrazie artystycznym i scenicznym oraz... nabraniu pewności siebie tak jako tancerki jak i kobiety! Zapraszamy wszystkie dziewczyny i kobiety (wiek nie ma znaczenia), dla których kursy to już za mało. Które kochają taniec, chcą odkrywać nowe pokłady kobiecości i charyzmy. Oraz te które zawsze marzyły żeby zdobyć pewność siebie. Wszystkie które czują, że ćwicząc technikę wzniosą swój taniec na wyższy level. Level, który pozwoli stanąć na scenie i spełnić marzenia!`,
      },
    },
    {
      isActive: true,
      id: 51,
      class: "dance51",
      type: "solo",
      name: "taniec brzucha",
      link: "taniec/taniec-brzucha",
      image: "instruktorzy/DSC_6780.jpg",
      headerImage: "instruktorzy/DSC_6780.jpg",
      video: "https://www.youtube.com/embed/8YQM_zfYO7Q",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Taniec brzucha to rodzaj tańca solowego, wykonywanego głównie ale nie wyłącznie przez kobiety, popularnego na terenach Bliskiego Wschodu i Afryki Północnej.
        Jego najważniejsze cechy to ruchy bioder, brzucha, biustu i ekspresyjne gesty rąk.
        Uważa się że kolebką tańca brzucha jest Egipt.
        Obecnie Taniec Brzucha jest bardzo modnym i lubianym tańcem wśród kobiet. Wpływa na poczucie kobiecości, zapewnia doskonały kontakt z własnym ciałem, pozwala na wyrażenie samej siebie bez ograniczeń, wyzwala z kompleksów dotyczących choćby zbyt obfitych bioder, gdyż w tym tańcu troszkę więcej ciała jest jak najbardziej wskazane. Dlatego też każda kobieta może się czuć w tym tańcu swobodnie i każda będzie go tańczyć inaczej. To taniec który pozwala kobiecie rozkwitnąć i wyrażać siebie.
        Uczy on miękkości ruchów, gracji, elegancji, kokieterii, ale także samoakceptacji i pewności siebie. Wzmacnia mięśnie kręgosłupa i miednicy, kształtuje sylwetkę, rozładowuje codzienny stres, wyzwala energię i radość.
        Taniec orientalny pozwala każdej kobiecie przeistoczyć się w księżniczkę z opowieści Szeherezady…`,
  
      },
    },
    {
      isActive: false,
      id: 52,
      class: "dance52",
      type: "solo",
      name: "modern ballet",
      link: "taniec/modern-ballet",
      image: "dances/Modern_ballet.jpg",
      headerImage: "dances/Modern_ballet.jpg",
      video: "https://www.youtube.com/",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Modern Ballet - Stanowi połączenie klasycznego baletu z tańcem współczesnym. Wykorzystuje technikę baletową, ale bez ograniczenia ciała formami i liniami, jak w klasycznym balecie. To wolność fantazji ruchu na baletowym fundamencie.`,
  
      },
    },
    {
      isActive: false,
      id: 53,
      class: "dance53",
      type: "danceFormation",
      name: "project flow",
      link: "taniec/project-flow",
      image: "instruktorzy/nikola.JPG",
      headerImage: "instruktorzy/nikola.JPG",
      video: "https://www.youtube.com/",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Project Flow to formacja której głównym stylem tańca jest Hip Hop ale bardzo szeroko pojęty.
        To formacja która ma wiele do powiedzenia na parkiecie to silna grupa ludzi w różnym wieku i z różnymi doświadczeniami.
        Project Flow to nie tylko taniec, Project Flow to pasja emocje i wytrwałość w dążeniu do celu!
        Instruktor grupy Nikola zaprasza na zajęcia :)
        Chcesz dołączyć do tej grupy? Zapraszamy! `,
  
      },
    },
    {
      isActive: false,
      id: 54,
      class: "dance54",
      type: "kids",
      name: "hip-hop-kids",
      link: "taniec/hip-hop-kids",
      image: "dances/feel_the_flow.jpeg",
      headerImage: "dances/feel_the_flow.jpeg",
      video: "https://www.youtube.com/",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Hip-hop to jeden z najpopularniejszych nowoczesnych stylów tanecznych. Można go zobaczyć w znanych filmach i teledyskach, a jego historia nieodzownie łączy się z muzyką o tej samej nazwie. Hip-hop zaliczany jest do Street Dance, czyli tańców ulicznych, i powstał na ulicach Nowego Jorku. Dzieli się na różne odmiany, np. oldschool, new style, new age, freestyle, co czyni go wyjątkowo różnorodnym, a każdy miłośnik tańca znajdzie w nim coś dla siebie. `,
  
      },
    },
    {
      isActive: false,
      id: 55,
      class: "dance55",
      type: "solo",
      name: "fit salsa",
      link: "taniec/fit-salsa",
      image: "dances/fitness_group.jpg",
      headerImage: "dances/fitness_group.jpg",
      video: "https://www.youtube.com/",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Fit Salsa to kurs tańca przeznaczony dla osób w każdym wieku o i różnych umiejętnościach tanecznych. Fit Salsa stanowi mieszankę muzyki kubańskiej, tańca oraz fitnessu. Jest nowatorskim programem opracowanym przez szkołę tańca Dance Flow i naszą instruktorkę. To znakomita zabawa, a jednocześnie efektywny trening w rytm muzyki karaibskiej. Podczas zajęć dużą wagę przywiązuje się do  techniki oraz stylu wykonania poszczególnych kroków. W przyjemny taneczny sposób spalamy kalorię i gubimy zbędne centymetry!!!`,
  
      },
    },
    {
      isActive: false,
      id: 56,
      class: "dance56",
      type: "saturday",
      name: "choreo",
      link: "taniec/choreo",
      image: "dances/choreo.png",
      headerImage: "dances/choreo.png",
      video: "https://www.youtube.com/",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Choreo (choreografia) to zajęcia z autorskimi choreografiami, która zawierają podstawy nowoczesnych stylów tanecznych. Choreo-styl to styl eksperymentalny, to utwór gra tutaj główną rolę i dyktuje choregorafię. Na zajęciach tancerz staje się bardziej wytrzymały fizycznie i przede wszystkim bardziej pewny siebie psychicznie, może wykazać się niezwykłą kreatywnścią oraz rozwinąć umiejętność pracy w zespole. Na Choreo nauczysz się sam stwarzać niepowtarzalne choreografie i je wykorzystywać.`,
  
      },
    },
    {
      isActive: true,
      id: 57,
      class: "dance57",
      type: "solo",
      name: "commercial dance",
      link: "taniec/commercial-dance",
      image: "instructors/nina.jpg",
      headerImage: "instructors/nina.jpg",
      video: "https://www.youtube.com/",
      poster: "/assets/poster.png",
      content: {
        middle: ``,
        acapits: "",
        end: `Commercial dance

        Commercial to taniec wielu emocji, wielu twarzy różnej ekspresji i dynamiki. To  w tych rytmach przeplatają się tańce street'owe (hip hop, house, dancehall) z sexy dance oraz jazzem. Commercial jest tańcem bardzo zmiksowanym, a jego efekty zobaczyć można w teledyskach gwiazd popu.
        
        `,
  
      },
    },
    {
    isActive: true,
    id: 58,
      class: "dance16",
      type: "solo",
      name: "dancehall",
      link: "taniec/dancehall",
      image: "dances/dancehallscene2.jpg",
      headerImage: "dances/dancehallscene2.jpg",
      video: "https://www.youtube.com/embed/RsZNfJKKelw",
      poster: "/assets/poster.png",
      content:{middle:``,acapits:"",end: `Dancehall to styl tańca wywodzący się z egzotycznej Jamajki, dzięki któremu poczujesz promienie słońca! Ten energiczny taniec sprawi, że spalisz mnóstwo kalorii przy pobudzających dźwiękach dancehallowej muzyki inspirowanej reggae. Kroki taneczne w dancehallu bazują na izolowanych i płynnych ruchach całego ciała`
    }}
  ],
};

const dancesReducer = (state = initialState, action) => {
  return state;
};

export default dancesReducer;
