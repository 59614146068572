export const SCHEDULE_HOURS = {
  // EIGHT_AM: "8:00",
  // NINE_AM: "9:00",
  // TEN_AM: "10:00",
  ELEVENT_AM: "11:00",
  TWELVE_AM: "12:00",
  ONE_PM: "13:00",
  TWO_PM: "14:00",
  THREE_PM: "15:00",
  THREE_FIVETEEN_PM: "15:15",
  FOUR_FIVETEEN_PM: "16:15",
  FOUR_PM: "16:00",
  FIVE_PM: "17:00",
  SIX_PM: "18:00",
  SEVEN_PM: "19:00",
  EIGHT_PM: "20:00",
  NINE_PM: "21:00"
};

export const SCHEDULE_DAYS = {
  MONDAY: "PONIEDZIAŁEK",
  TUESDAY: "WTOREK",
  WEDNESDAY: "ŚRODA",
  THURSDAY: "CZWARTEK",
  FRIDAY: "PIĄTEK",
  SATURDAY: "SOBOTA",
  //SUNDAY: "NIEDZIELA"
};
