import { LEVELS } from "../../consts/levels";
import { ENROLLMENT_STATUSES } from "../../consts/enrollmentStatus";
import { SCHEDULE_HOURS } from "../../consts/scheduleTimes";
import { SCHEDULE_DAYS } from "../../consts/scheduleTimes";
import * as cardColors from "../../consts/cardColors";

const initialState = {
  scheduleItems: [
    {
      danceId: 14,
      time: SCHEDULE_HOURS.SEVEN_PM,
      instructorId: 2,
      instructorName: "",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.MONDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 14,
      time: SCHEDULE_HOURS.SIX_PM,
      instructorId: 2,
      instructorName: "Karolina",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.OPEN,
      day: SCHEDULE_DAYS.THURSDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 57,
      time: SCHEDULE_HOURS.SIX_PM,
      instructorId: 2,
      instructorName: "",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.TUESDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 57,
      time: SCHEDULE_HOURS.SEVEN_PM,
      instructorId: 4,
      instructorName: "",
      level: LEVELS.LEVEL_1,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.FRIDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 15,
      time: SCHEDULE_HOURS.FIVE_PM,
      instructorId: 4,
      customName: "Hip Hop",
      instructorName: "",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.OPEN,
      day: SCHEDULE_DAYS.FRIDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 3,
      time: SCHEDULE_HOURS.SEVEN_PM,
      instructorId: 1,
      instructorName: "",
      level: LEVELS.LEVEL_1,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.WEDNESDAY,
      color: cardColors.IN_PAIRS
    },
    {
      danceId: 8,
      time: SCHEDULE_HOURS.SIX_PM,
      instructorId: 1,
      instructorName: "",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.OPEN,
      day: SCHEDULE_DAYS.MONDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 51,
      time: SCHEDULE_HOURS.SEVEN_PM,
      instructorId: 1,
      instructorName: "",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.MONDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 13,
      time: SCHEDULE_HOURS.EIGHT_PM,
      instructorId: 2,
      instructorName: "",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.MONDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 16,
      time: SCHEDULE_HOURS.THREE_PM,
      instructorId: 2,
      instructorName: "Kamila",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.SATURDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 6,
      time: SCHEDULE_HOURS.FIVE_PM,
      instructorId: 6,
      instructorName: "",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.THURSDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 6,
      time: SCHEDULE_HOURS.SIX_PM,
      instructorId: 6,
      instructorName: "",
      level: LEVELS.LEVEL_1_2,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.THURSDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 10,
      time: SCHEDULE_HOURS.FIVE_PM,
      instructorId: 1,
      instructorName: "",
      level: LEVELS.OPEN,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.FRIDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 9,
      time: SCHEDULE_HOURS.ONE_PM,
      instructorId: 1,
      instructorName: "Kamila",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.SATURDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 19,
      time: SCHEDULE_HOURS.SIX_PM,
      instructorId: 1,
      instructorName: "Alexa",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.OPEN,
      day: SCHEDULE_DAYS.FRIDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 11,
      time: SCHEDULE_HOURS.SEVEN_PM,
      instructorId: 1,
      instructorName: "Alexa",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.OPEN,
      day: SCHEDULE_DAYS.FRIDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 16,
      time: SCHEDULE_HOURS.TWO_PM,
      instructorId: 1,
      instructorName: "Kamila",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.SATURDAY,
      color: cardColors.SOLO
    },

    //Sala 2
    {
      danceId: 10,
      time: SCHEDULE_HOURS.FIVE_PM,
      instructorId: 1,
      instructorName: "",
      level: LEVELS.OPEN,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.TUESDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 3,
      time: SCHEDULE_HOURS.SEVEN_PM,
      instructorId: 1,
      instructorName: "",
      level: LEVELS.LEVEL_3,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.TUESDAY,
      color: cardColors.IN_PAIRS
    },
    {
      danceId: 2,
      time: SCHEDULE_HOURS.EIGHT_PM,
      instructorId: 1,
      instructorName: "Klara i Przem",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.OPEN,
      day: SCHEDULE_DAYS.TUESDAY,
      color: cardColors.IN_PAIRS
    },
    {
      danceId: 14,
      customName: "",
      time: SCHEDULE_HOURS.SEVEN_PM,
      instructorId: 1,
      instructorName: "Karolina",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.OPEN,
      day: SCHEDULE_DAYS.THURSDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 13,
      time: SCHEDULE_HOURS.EIGHT_PM,
      instructorId: 2,
      instructorName: "",
      level: LEVELS.LEVEL_2,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.TUESDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 19,
      time: SCHEDULE_HOURS.NINE_PM,
      instructorId: 2,
      instructorName: "",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.MONDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 14,
      time: SCHEDULE_HOURS.SEVEN_PM,
      instructorId: 2,
      instructorName: "",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.WEDNESDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 19,
      time: SCHEDULE_HOURS.EIGHT_PM,
      instructorId: 2,
      instructorName: "",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.THURSDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 11,
      time: SCHEDULE_HOURS.SIX_PM,
      instructorId: 2,
      instructorName: "",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.WEDNESDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 11,
      time: SCHEDULE_HOURS.SEVEN_PM,
      instructorId: 2,
      instructorName: "",
      level: LEVELS.LEVEL_1,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.TUESDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 19,
      time: SCHEDULE_HOURS.SIX_PM,
      customName: "",
      instructorId: 2,
      instructorName: "",
      level: LEVELS.LEVEL_1,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.MONDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 11,
      time: SCHEDULE_HOURS.SEVEN_PM,
      instructorId: 2,
      instructorName: "",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.THURSDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 13,
      time: SCHEDULE_HOURS.EIGHT_PM,
      instructorId: 2,
      customName: "",
      instructorName: "",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.OPEN,
      day: SCHEDULE_DAYS.WEDNESDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 19,
      time: SCHEDULE_HOURS.NINE_PM,
      instructorId: 2,
      instructorName: "",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.OPEN,
      day: SCHEDULE_DAYS.WEDNESDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 10,
      time: SCHEDULE_HOURS.FIVE_PM,
      instructorId: 1,
      instructorName: "",
      level: LEVELS.OPEN,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.WEDNESDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 58,
      time: SCHEDULE_HOURS.FIVE_PM,
      instructorId: 1,
      instructorName: "Jagoda",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.TUESDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 6,
      time: SCHEDULE_HOURS.SIX_PM,
      instructorId: 1,
      instructorName: "",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.WEDNESDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 13,
      time: SCHEDULE_HOURS.FIVE_PM,
      customName: "",
      instructorId: 2,
      instructorName: "Agnieszka",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.OPEN,
      day: SCHEDULE_DAYS.MONDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 29,
      time: SCHEDULE_HOURS.EIGHT_PM,
      customName: "",
      instructorId: 1,
      instructorName: "Karolina",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.OPEN,
      day: SCHEDULE_DAYS.THURSDAY,
      color: cardColors.IN_PAIRS
    },
    {
      danceId: 3,
      time: SCHEDULE_HOURS.NINE_PM,
      customName: "",
      instructorId: 1,
      instructorName: "Karolina",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.OPEN,
      day: SCHEDULE_DAYS.THURSDAY,
      color: cardColors.IN_PAIRS
    },
    {
      danceId: 3,
      time: SCHEDULE_HOURS.EIGHT_PM,
      customName: "",
      instructorId: 1,
      instructorName: "",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.MONDAY,
      color: cardColors.IN_PAIRS
    },
    {
      danceId: 10,
      time: SCHEDULE_HOURS.FIVE_PM,
      customName: "",
      instructorId: 1,
      instructorName: "",
      level: LEVELS.OPEN,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.MONDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 3,
      time: SCHEDULE_HOURS.EIGHT_PM,
      customName: "",
      instructorId: 1,
      instructorName: "",
      level: LEVELS.LEVEL_2,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.WEDNESDAY,
      color: cardColors.IN_PAIRS
    },
    {
      danceId: 3,
      time: SCHEDULE_HOURS.SIX_PM,
      instructorId: 1,
      instructorName: "",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.OPEN,
      day: SCHEDULE_DAYS.TUESDAY,
      color: cardColors.IN_PAIRS
    },
    {
      danceId: 13,
      time: SCHEDULE_HOURS.NINE_PM,
      instructorId: 2,
      instructorName: "",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.THURSDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 2,
      time: SCHEDULE_HOURS.NINE_PM,
      instructorId: 1,
      instructorName: "Klara i Przem",
      level: LEVELS.LEVEL_1,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.TUESDAY,
      color: cardColors.IN_PAIRS
    },
    {
      danceId: 57,
      time: SCHEDULE_HOURS.SIX_PM,
      instructorId: 4,
      instructorName: "",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.JOIN,
      day: SCHEDULE_DAYS.FRIDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 35,
      time: SCHEDULE_HOURS.THREE_FIVETEEN_PM,
      instructorId: 4,
      instructorName: "Neder",
      level: LEVELS.AGE_4_6,
      recruitment: ENROLLMENT_STATUSES.OPEN,
      day: SCHEDULE_DAYS.WEDNESDAY,
      color: cardColors.KIDS
    },
    {
      danceId: 44,
      time: SCHEDULE_HOURS.FOUR_PM,
      instructorId: 4,
      instructorName: "Neder",
      level: LEVELS.AGE_7_13,
      recruitment: ENROLLMENT_STATUSES.OPEN,
      day: SCHEDULE_DAYS.WEDNESDAY,
      color: cardColors.KIDS
    },
    {
      danceId: 44,
      time: SCHEDULE_HOURS.FIVE_PM,
      instructorId: 4,
      instructorName: "Neder",
      level: LEVELS.AGE_14_18,
      recruitment: ENROLLMENT_STATUSES.OPEN,
      day: SCHEDULE_DAYS.WEDNESDAY,
      color: cardColors.KIDS
    },
    {
      danceId: 29,
      time: SCHEDULE_HOURS.FIVE_PM,
      instructorId: 2,
      instructorName: "Karolina",
      level: LEVELS.AGE_7_13,
      recruitment: ENROLLMENT_STATUSES.OPEN,
      day: SCHEDULE_DAYS.THURSDAY,
      color: cardColors.KIDS
    },
    {
      danceId: 35,
      time: SCHEDULE_HOURS.FOUR_FIVETEEN_PM,
      instructorId: 2,
      instructorName: "Karolina",
      level: LEVELS.AGE_4_6,
      recruitment: ENROLLMENT_STATUSES.OPEN,
      day: SCHEDULE_DAYS.THURSDAY,
      color: cardColors.KIDS
    },
    {
      danceId: 19,
      time: SCHEDULE_HOURS.ELEVENT_AM,
      instructorId: 2,
      instructorName: "Ilona",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.OPEN,
      day: SCHEDULE_DAYS.SATURDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 48,
      time: SCHEDULE_HOURS.TWELVE_AM,
      instructorId: 2,
      instructorName: "Ilona",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.OPEN,
      day: SCHEDULE_DAYS.SATURDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 44,
      time: SCHEDULE_HOURS.ELEVENT_AM,
      instructorId: 2,
      instructorName: "Dominika",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.OPEN,
      day: SCHEDULE_DAYS.SATURDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 11,
      time: SCHEDULE_HOURS.TWELVE_AM,
      instructorId: 2,
      instructorName: "Dominika",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.OPEN,
      day: SCHEDULE_DAYS.SATURDAY,
      color: cardColors.SOLO
    },
    {
      danceId: 19,
      time: SCHEDULE_HOURS.ONE_PM,
      instructorId: 2,
      instructorName: "Dominika",
      level: LEVELS.LEVEL_0,
      recruitment: ENROLLMENT_STATUSES.OPEN,
      day: SCHEDULE_DAYS.SATURDAY,
      color: cardColors.SOLO
    },
  ]
};

const scheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default scheduleReducer;
